import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../useWindowDimensions'
import InputText from '../../component/InputText/InputText'
import Button from '../../component/Button/Button'
import order from '../../images/login/order.png'
import quick from '../../images/login/quick.png'
import safer from '../../images/login/safer.png'
import logo from '../../images/login/Wyngs assets-01.png'
import WOW from 'wowjs'

import './style.less'
import 'swiper/swiper-bundle.css'

import { Link } from 'react-router-dom/cjs/react-router-dom'
import Header from '../../component/Header/Header'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import urldata from '../../urldata'

const Login = (props) => {
	const { t } = useTranslation()
	const { height, width } = useWindowDimensions()

	const [loginPage, setLoginPage] = useState(true)
	const [otp, setOtp] = useState('')
	const [name, setName] = useState('')
	const [nameErr, setNameErr] = useState('')
	const [email, setEmail] = useState('')
	const [emailerr, serEmailerr] = useState('')
	const [nameerr, setNameerr] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumbererr, setPhoneNumbererr] = useState('')

	const [password, setpassword] = useState('')
	const [passworderr, setPassworderr] = useState('')

	const [city, setCity] = useState('')
	const [cityErr, setCityErr] = useState('')

	const [showEmailVerifcationModel, setShowEmailVerifcationModel] =
		useState(false)

	const [showForgotPassowrdModel, setShowForgotPassowrdModel] = useState(false)

	const [showForgotPassowrd2Model, setShowForgotPassowrd2Model] =
		useState(false)

	const linkStyle = {
		margin: '1em',
		color: '#4285F4',
		fontFamily: 'Mulish',
	}
	const linkStyle1 = {
		margin: '0.5em',
		textDecoration: 'none',
		fontSize: '20px',
		color: ' #4285F4',
		fontWeight: 'bold',
		fontFamily: 'Mulish',
	}

	useEffect(() => {
		window.scroll(0, 0)
		document.title = 'Wyngs - Login'
		new WOW.WOW({
			live: false,
		}).init()
	}, [])

	const handleClose = () => setShowEmailVerifcationModel(false)
	const handleForgotPasswordClose = () => setShowForgotPassowrdModel(false)
	const handleForgotPassword2Close = () => setShowForgotPassowrd2Model(false)

	const signUpBtnClick = async () => {
		var formIsValid = true

		if (typeof email !== 'undefined') {
			//regular expression for email validation
			var pattern = new RegExp(/^\S+@\S+\.\S+$/)
			if (!pattern.test(email)) {
				formIsValid = false
				serEmailerr('*Please enter valid email-Id.')
			} else {
				serEmailerr('')
			}
		}
		if (!password) {
			formIsValid = false
			setPassworderr('*Please enter your password.')
		} else {
			setPassworderr('')
		}
		if (!name) {
			formIsValid = false
			setNameErr('*Please enter your name.')
		} else {
			setPassworderr('')
		}
		if (!city) {
			formIsValid = false
			setCityErr('*Please enter your city.')
		} else {
			setPassworderr('')
		}

		if (!phoneNumber) {
			formIsValid = false
			setPhoneNumbererr('*Please enter your phone Number.')
		} else {
			setPassworderr('')
		}
		if (formIsValid) {
			try {
				const data = await axios({
					method: 'POST',

					url: 'https://wyngs.speedboxapp.com/speedbox/api/v1/app/signup/',
					data: {
						name,
						email,
						password,
						_countryId: '5c2381eb7a2c995b5341b2ba',
						countryCode: 60,
						phoneNumber,
						acceptCondition: true,
						skipToken: false,
						systemId: city,
					},
				})
				setShowEmailVerifcationModel(true)
			} catch (error) {
				alert(error?.response?.data?.msg || 'Something went wrong')
			}
		}
	}

	const submitBtnClick = async () => {
		var formIsValid = true

		if (typeof email !== 'undefined') {
			//regular expression for email validation
			var pattern = new RegExp(/^\S+@\S+\.\S+$/)
			if (!pattern.test(email)) {
				formIsValid = false
				serEmailerr('*Please enter valid email-Id.')
			} else {
				serEmailerr('')
			}

			if (!password) {
				formIsValid = false
				setPassworderr('*Please enter your password.')
			} else {
				setPassworderr('')
			}
		}
		if (formIsValid) {
			try {
				const data = await axios({
					method: 'POST',

					url: urldata + 'admin/dashboard/login',
					data: {
						email,
						password,
					},
				})
				localStorage.setItem('isDashboardLogin', true)
				window.location.href = data.data.data
			} catch (error) {
				setPassworderr(error?.response?.data?.message || 'Something went wrong')
			}
		}
	}

	const handleResendOtpClick = async () => {
		try {
			await axios({
				method: 'POST',

				url: 'https://wyngs.speedboxapp.com/speedbox/api/v1/app/signup/resendverification',
				data: {
					email,
				},
			})
			alert('Otp Sent Sucessfully')
		} catch (error) {
			alert(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleVerifyBtnClick = async () => {
		try {
			await axios({
				method: 'POST',
				url: 'https://wyngs.speedboxapp.com/speedbox/api/v1/app/signup/confirmuser',
				data: {
					email,
					code: otp,
				},
			})
			setShowEmailVerifcationModel(false)
			setLoginPage(true)
		} catch (error) {
			alert(error?.response?.data?.msg || 'Something went wrong')
		}
	}

	const handleForgotPasswordBtnClick = async () => {
		try {
			await axios({
				method: 'POST',
				url: 'https://wyngs.speedboxapp.com/speedbox/api/v1/app/forgotpassword',
				data: {
					email,
				},
			})
			setShowForgotPassowrdModel(false)
			setShowForgotPassowrd2Model(true)
		} catch (error) {
			alert(error?.response?.data?.msg || 'Something went wrong')
		}
	}

	const handleResetPassword = async () => {
		try {
			await axios({
				method: 'POST',
				url: 'https://wyngs.speedboxapp.com/speedbox/api/v1/app/forgotpassword/confirm',
				data: {
					email,
					verificationCode: otp,
					password,
				},
			})
			setShowForgotPassowrd2Model(false)
		} catch (error) {
			alert(error?.response?.data?.msg || 'Something went wrong')
		}
	}

	return (
		<>
			<Header />
			{loginPage ? (
				<div className='login_sec1 d-f'>
					{width >= 1024 ? (
						<>
							<div className='logoContainer'>
								<img className='fluid' src={logo} />
							</div>
							<div className='imgContainer mx-auto d-block'>
								<img className='fluid' src={order} />
							</div>
							<br />
							<div className='infoContainer d-block' id='bottom'>
								<div className='login-heading'>{t('LoginObj.heading1')}</div>
								<div className='login-text'>{t('LoginObj.desc1')}</div>
							</div>
						</>
					) : null}

					<div className='container'>
						<div className='card'>
							{/* <div className="row"> */}
							<div className='info'>
								{t('LoginObj.welcome')}
								<span>{t('LoginObj.WYNGS')}</span>
							</div>

							{/* </div> */}
							<div className='title'>{t('LoginObj.Login')}</div>

							<div className='formContainer w-f d-f f-c'>
								<div className='mt-1'>
									<InputText
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										title={t('LoginObj.Label1')}
									/>
									<div className='error'>{emailerr}</div>
								</div>

								<div className='mt-1'>
									<InputText
										value={password}
										type='password'
										onChange={(e) => setpassword(e.target.value)}
										title={t('LoginObj.Label2')}
									/>
									<div className='error'>{passworderr}</div>
								</div>

								<div className='forgot'>
									<Link
										onClick={() => setShowForgotPassowrdModel(true)}
										style={linkStyle}
									>
										{t('LoginObj.Forgot')}
									</Link>
								</div>

								<div className='text1'>
									{t('LoginObj.Account')}&nbsp;&nbsp;
									<span onClick={() => setLoginPage(false)}>
										{t('LoginObj.Signup')}
									</span>
								</div>

								<div className='submitbtnContainer'>
									<Button
										orange={true}
										title={t('LoginObj.Login')}
										onClick={submitBtnClick}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='login_sec1 d-f'>
					{width >= 1024 ? (
						<>
							<div className='logoContainer'>
								<img className='fluid' src={logo} />
							</div>
							<div className='imgContainer mx-auto d-block'>
								<img className='fluid' src={order} />
							</div>
							<br />
							<div className='infoContainer d-block' id='bottom'>
								<div className='login-heading'>{t('LoginObj.heading1')}</div>
								<div className='login-text'>{t('LoginObj.desc1')}</div>
							</div>
						</>
					) : null}
					<div className='container'>
						<div className='card'>
							{/* <div className="row"> */}
							<div className='info'>
								{t('LoginObj.welcome')}
								<span>{t('LoginObj.WYNGS')}</span>
							</div>

							{/* </div> */}
							<div className='title'>{t('LoginObj.Signup')}</div>

							<div className='formContainer w-f d-f f-c'>
								<div className='mt-1'>
									<InputText
										value={name}
										onChange={(e) => setName(e.target.value)}
										title={t('LoginObj.nameField')}
									/>
									<div className='error'>{nameErr}</div>
								</div>
								<div className='mt-1'>
									<InputText
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										title={t('LoginObj.Label1')}
									/>
									<div className='error'>{emailerr}</div>
								</div>

								<div className='mt-1'>
									<InputText
										value={password}
										type='password'
										onChange={(e) => setpassword(e.target.value)}
										title={t('LoginObj.Label2')}
									/>
									<div className='error'>{passworderr}</div>
								</div>

								<div className='mt-1'>
									<InputText
										value='Malaysia'
										title={t('LoginObj.countryField')}
									/>
								</div>

								<div className='mt-1'>
									<InputText
										value={city}
										dropdown
										list={[
											{
												name: '',
												value: '',
											},
											{
												name: 'MY_Klang Valley',
												value: '606c0d1a446c7b00095f32c6',
											},
											{
												name: 'WYNGS_ SABAH',
												value: '6074069ff3fb5c00092dd978',
											},
											{
												name: 'WYNGS_ SARAWAK',
												value: '60744c2e374fb30008aaf24b',
											},
										]}
										onChange={(e) => setCity(e.target.value)}
										title={t('LoginObj.cityField')}
									/>
									<div className='error'>{cityErr}</div>
								</div>

								<div className='mt-1'>
									<InputText
										value={phoneNumber}
										onChange={(e) => setPhoneNumber(e.target.value)}
										title={t('LoginObj.phoneField')}
									/>
									<div className='error'>{phoneNumbererr}</div>
								</div>

								<div className='forgot'>
									<Link
										onClick={() => setShowForgotPassowrdModel(true)}
										style={linkStyle}
									>
										{t('LoginObj.Forgot')}
									</Link>
								</div>

								<div className='text1'>
									{t('LoginObj.Have an Account')}&nbsp;&nbsp;
									<span onClick={() => setLoginPage(true)}>
										{t('LoginObj.Login')}
									</span>
								</div>

								<div className='submitbtnContainer'>
									<Button
										orange={true}
										title={t('LoginObj.Signup')}
										onClick={signUpBtnClick}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				{...props}
				//   size="lg"
				//   aria-labelledby="contained-modal-title-vcenter"
				className='otpVerificationModel'
				centered
				show={showEmailVerifcationModel}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<h3>Verify Email</h3>
				</Modal.Header>
				<Modal.Body className='model'>
					<div>
						A verification code has been sent to your email address {email}.
						Please verify your email by entering that code here.
					</div>

					<InputText
						value={otp}
						onChange={(e) => setOtp(e.target.value)}
						title='Verification Code'
					/>

					<div className='resendOtpBtn' onClick={handleResendOtpClick}>
						Resend verification code?
					</div>

					<br></br>
					<div className='btnContainer d-f j-c  mt-4'>
						<Button onClick={handleVerifyBtnClick} title='verify' />
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				{...props}
				//   size="lg"
				//   aria-labelledby="contained-modal-title-vcenter"
				className='forgotPasswordModel'
				centered
				show={showForgotPassowrdModel}
				onHide={handleForgotPasswordClose}
			>
				<Modal.Header closeButton>
					<h3>Forgot your password</h3>
				</Modal.Header>
				<Modal.Body className='model'>
					<InputText
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						title='Email'
					/>

					<br></br>
					<div className='btnContainer d-f j-c  mt-4'>
						<Button
							onClick={handleForgotPasswordBtnClick}
							title='Send Verification Code'
						/>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				{...props}
				//   size="lg"
				//   aria-labelledby="contained-modal-title-vcenter"
				className='ResetPasswordModel'
				centered
				show={showForgotPassowrd2Model}
				onHide={handleForgotPassword2Close}
			>
				<Modal.Header closeButton>
					<h3>Reset Password</h3>
				</Modal.Header>
				<Modal.Body className='model'>
					<div>
						A verification code has been sent to your email ID {email}. . Please
						reset your password by entering that code and a new password here.
					</div>

					<InputText
						value={otp}
						onChange={(e) => setOtp(e.target.value)}
						title='Verification Code'
					/>

					<InputText
						value={password}
						onChange={(e) => setpassword(e.target.value)}
						title='New Password'
					/>

					<br></br>
					<div className='btnContainer d-f j-c  mt-4'>
						<Button
							onClick={handleResetPassword}
							title='Send Verification Code'
						/>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Login
